import AppFrame from '../components/shared/AppFrame'
import BatchList from '../components/batches/BatchList'

export default function Batches() {
  return (
    <>
      <AppFrame>
        <BatchList />
      </AppFrame>
    </>
  )
}
