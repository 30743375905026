export default function Button({ text, handleClick }) {
  return (
    <>
      <button
        type='button'
        onClick={handleClick}
        className='block rounded-md bg-theme-400 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-theme-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-400'
      >
        {text}
      </button>
    </>
  )
}
