function ProductSubcribe({ authentic }) {
  const onSubmit = (e) => {
    e.preventDefault()
    console.log('Submitted')

    const connectionData = {
      email: 'test@test.com',
    }
    //dispatch(createUser(userData))
    console.log('Connection data', connectionData)
  }

  if (!authentic) return false
  return (
    <>
      <div className='p-4 mt-10'>
        Would you like to know about new products and specials?
      </div>
      <form className='space-y-6 p-4'>
        <div>
          <label
            htmlFor='email'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Email address:
          </label>
          <div className='mt-2'>
            <input
              id='email'
              name='email'
              type='email'
              autoComplete='email'
              required
              className='block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-700 sm:text-sm sm:leading-6'
            />
          </div>
        </div>

        <div>
          <button
            type='submit'
            className='flex w-full justify-center rounded-md bg-theme-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-theme-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-700'
          >
            Connect
          </button>
        </div>
      </form>
    </>
  )
}

export default ProductSubcribe
