import { useState } from 'react'
import Button from '../shared/Button'
import BatchModal from '../product/BatchModal'
import { useParams } from 'react-router-dom'

const products = [
  {
    id: 1,
    batches: [
      {
        id: 1,
        name: '20240517.SG',
        items: 200,
        scans: 823,
        feedback: 12,
        subscribers: 7,
      },
    ],
  },
  {
    id: 2,
    batches: [
      {
        id: 1,
        name: '20240516.LMCB',
        items: 5,
        scans: 823,
        feedback: 12,
        subscribers: 7,
      },
      {
        id: 2,
        name: '20240510.LMCB',
        items: 200,
        scans: 618,
        feedback: 167,
        subscribers: 145,
      },
      {
        id: 3,
        name: '20240501.LMCB',
        items: 200,
        scans: 212,
        feedback: 32,
        subscribers: 3,
      },
      {
        id: 4,
        name: '20240494.LMCB',
        items: 200,
        scans: 358,
        feedback: 18,
        subscribers: 7,
      },
      {
        id: 5,
        name: '20240479.LMCB',
        items: 50,
        scans: 34,
        feedback: 5,
        subscribers: 2,
      },
      {
        id: 6,
        name: '20240449.LMCB',
        items: 200,
        scans: 928,
        feedback: 97,
        subscribers: 56,
      },
      {
        id: 7,
        name: '20240434.LMCB',
        items: 200,
        scans: 1026,
        feedback: 78,
        subscribers: 42,
      },
    ],
  },
  {
    id: 3,
    batches: [
      {
        id: 1,
        name: '20240321.BLC',
        items: 100,
        scans: 45,
        feedback: 15,
        subscribers: 11,
      },
      {
        id: 2,
        name: '20240319.BLC',
        items: 100,
        scans: 85,
        feedback: 12,
        subscribers: 2,
      },
      {
        id: 3,
        name: '20240317.BLC',
        items: 100,
        scans: 92,
        feedback: 18,
        subscribers: 7,
      },
      {
        id: 4,
        name: '20240315.BLC',
        items: 100,
        scans: 108,
        feedback: 24,
        subscribers: 7,
      },
      {
        id: 5,
        name: '20240313.BLC',
        items: 100,
        scans: 156,
        feedback: 36,
        subscribers: 9,
      },
    ],
  },
  {
    id: 4,
    batches: [
      {
        id: 1,
        name: '20240426.ACIC',
        items: 150,
        scans: 196,
        feedback: 12,
        subscribers: 7,
      },
      {
        id: 2,
        name: '20240423.ACIC',
        items: 150,
        scans: 210,
        feedback: 18,
        subscribers: 11,
      },
      {
        id: 3,
        name: '20240420.ACIC',
        items: 150,
        scans: 221,
        feedback: 28,
        subscribers: 14,
      },
      {
        id: 4,
        name: '20240417.ACIC',
        items: 150,
        scans: 286,
        feedback: 46,
        subscribers: 27,
      },
    ],
  },
]

export default function BatchList(b) {
  const [showModal, setShowModal] = useState(false)
  const { pid } = useParams()
  const product = products.find(({ id }) => id == 1)

  let batches = []
  if (b) {
    batches = b.batches
  }

  function openModal() {
    console.log('Opening Modal')
    setShowModal(true)
  }

  function closeModal() {
    console.log('closing modal')
    setShowModal(false)
  }

  return (
    <>
      <div className='mb-12'>
        <div className='sm:flex sm:items-center'>
          <div className='sm:flex-auto'>
            <h1 className='text-base font-semibold leading-6 text-gray-900'>
              Batches
            </h1>
            <p className='mt-2 text-sm text-gray-700'>
              A list of all the batches that you have registered.
              Click the downlaod button to download your QR codes for
              each item.
            </p>
          </div>
          <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
            <Button
              handleClick={openModal}
              text='Add Batch'
            />
          </div>
        </div>
        <div className='mt-8 flow-root'>
          <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                    >
                      Batch Name
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Item Count
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Number of Scans
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Feedback
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Connections
                    </th>
                    <th
                      scope='col'
                      className='relative py-3.5 pl-3 pr-4 sm:pr-3'
                    >
                      <span className='sr-only'>Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white'>
                  {batches.map((batch) => (
                    <tr
                      key={batch.id}
                      className='even:bg-gray-50'
                    >
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                        {batch.name}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {batch.itemCount}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        0
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        0
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        0
                      </td>
                      <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3'>
                        <a
                          href='#'
                          className='text-theme-400 hover:text-theme-500'
                        >
                          Download
                          <span className='sr-only'>
                            , {batch.id}
                          </span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal && <BatchModal closeModal={closeModal} />}
    </>
  )
}
