import ButtonLink from '../shared/ButtonLink'
import CompanyInformation from './CompanyInformation'

function MailForm() {
  return (
    <>
      <p className='p-4'>
        {' '}
        Enter your information below to receive a mail label to send
        your product back for free. Once we receive your product we
        will send you a product replacement.
      </p>
      <form className='space-y-6 p-4'>
        <div>
          <label
            htmlFor='name'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Name:
          </label>
          <div className='mt-2'>
            <input
              id='name'
              name='name'
              type='text'
              autoComplete='name'
              placeholder='Add Name'
              required
              className='block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-700 sm:text-sm sm:leading-6'
            />
          </div>
        </div>

        <div>
          <label
            htmlFor='email'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Email address:
          </label>
          <div className='mt-2'>
            <input
              id='email'
              name='email'
              type='email'
              autoComplete='email'
              placeholder='Add Email Address'
              required
              className='block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-700 sm:text-sm sm:leading-6'
            />
          </div>
        </div>

        <div>
          <label
            htmlFor='address'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Address:
          </label>
          <div className='mt-2'>
            <input
              id='address'
              name='address'
              type='text'
              autoComplete='address'
              placeholder='Add Your Address'
              required
              className='block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-700 sm:text-sm sm:leading-6'
            />
          </div>
        </div>

        <div>
          <label
            htmlFor='address2'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Address 2:
          </label>
          <div className='mt-2'>
            <input
              id='address2'
              name='address2'
              type='text'
              autoComplete='address2'
              placeholder='Add Additional Address Information'
              className='block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-700 sm:text-sm sm:leading-6'
            />
          </div>
        </div>

        <div>
          <label
            htmlFor='city'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            City:
          </label>
          <div className='mt-2'>
            <input
              id='city'
              name='city'
              type='text'
              autoComplete='city'
              placeholder='Add Your City'
              required
              className='block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-700 sm:text-sm sm:leading-6'
            />
          </div>
        </div>

        <div>
          <label
            htmlFor='state'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            State:
          </label>
          <div className='mt-2'>
            <input
              id='state'
              name='state'
              type='text'
              autoComplete='state'
              placeholder='Add Your State'
              required
              className='block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-700 sm:text-sm sm:leading-6'
            />
          </div>
        </div>

        <div>
          <label
            htmlFor='zip'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Zip Code:
          </label>
          <div className='mt-2'>
            <input
              id='zip'
              name='zip'
              type='text'
              autoComplete='zip'
              placeholder='Add Your Zip Code'
              required
              className='block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-700 sm:text-sm sm:leading-6'
            />
          </div>
        </div>

        <div>
          <ButtonLink
            handleClick={'/shipping'}
            text='Get Shipping Label'
            className='flex w-full justify-center rounded-md bg-theme-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-theme-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-700'
          >
            Get Shipping Label
          </ButtonLink>
        </div>
      </form>
    </>
  )
}

export default MailForm
