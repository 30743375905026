import { PaperClipIcon } from '@heroicons/react/20/solid'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import Button from '../shared/Button'
import ButtonLink from '../shared/ButtonLink'
import ProductModalEdit from './ProductModalEdit'

export default function ProductInfo(p) {
  //const { pid } = useParams()
  //const product = products.find(({ id }) => id == pid)

  const product = p.product

  console.log('Product from within ProductInfo ', product)
  const [showModal, setShowModal] = useState(false)

  function openModal() {
    console.log('Opening Modal')
    setShowModal(true)
  }

  function closeModal() {
    console.log('closing modal')
    setShowModal(false)
  }

  return (
    <>
      <div className='mb-8'>
        <div className='sm:flex sm:items-center'>
          <div className='sm:flex-auto'>
            <h1 className='text-base font-semibold leading-7 text-gray-900'>
              {product.title}
            </h1>
            <p className='mt-2 text-sm text-gray-700'>
              A list of all the batches that you have registered.
              Click the downlaod button to download your QR codes for
              each item.
            </p>
          </div>
          <div className='mt-4 sm:ml-4 sm:mt-0 sm:flex-none'>
            <ButtonLink
              handleClick={'/m/' + product.id}
              text='Preview Product'
            />
          </div>
          <div className='mt-4 sm:ml-4 sm:mt-0 sm:flex-none'>
            <Button
              handleClick={openModal}
              text='Edit Product'
            />
          </div>
        </div>
        <div className='mt-6 border-t border-gray-100'>
          <dl className='divide-y divide-gray-100'>
            <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Product Title
              </dt>
              <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                {product.title}
              </dd>
            </div>
            <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Product Description
              </dt>
              <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                {product.description}
              </dd>
            </div>
            <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Include Company Information
              </dt>
              <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                {product.include_company ? 'Yes' : 'No'}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      {showModal && (
        <ProductModalEdit
          closeModal={closeModal}
          data={product}
        />
      )}
    </>
  )
}
