import Button from './Button'

function Modal({ closeModal, title, children }) {
  function handleClick() {
    console.log('Do something with this modal.')
    closeModal()
  }
  return (
    <div className='fixed top-0 left-0 w-screen h-screen bg-gray-700/50 flex flex-col justify-center items-center z-50'>
      <div className='bg-white p-4 m-4 rounded-lg md:w-7/12 w-10/12 max-w-screen-md shadow-2xl relative'>
        <a
          className='absolute right-5 text-2xl hover:cursor-pointer'
          onClick={closeModal}
        >
          x
        </a>
        <h1 className='text-2xl py-8 font-bold text-center'>
          {title}
        </h1>
        <div className='h-1 w-10/12 bg-theme-300 mx-auto mb-8'></div>
        {children}
      </div>
    </div>
  )
}

export default Modal
