import NFTLink from './NFTLink'

function ProductTitle({ title, nft, id2, authentic, track }) {
  const url = 'https://opensea.io/assets/ethereum/' + nft + '/' + id2
  console.log(title)
  return (
    <>
      <div className='align-middle'>
        <h1 className='text-bold text-xl text-center mt-5'>
          {title}
        </h1>
      </div>
      <NFTLink
        authentic={authentic}
        track={track}
        url={url}
        nft={nft}
      />
    </>
  )
}

export default ProductTitle
