import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getProduct } from '../../features/products/productSlice'
import Spinner from '../../components/shared/Spinner'
import AppFrame from '../../components/shared/AppFrame'
import ProductInfo from '../../components/product/ProductInfo'
import BatchList from '../../components/product/BatchList'
import ProductImages from '../../components/product/ProductImages'
import FeedbackList from '../../components/product/FeedbackList'

export default function Product() {
  const { pid } = useParams()

  const { product, isSaved, isError, isLoading, isSuccess, message } =
    useSelector((state) => state.products)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProduct(pid))
  }, [dispatch])

  //console.log('PRODUCT', product.data.images)
  return isSuccess ? (
    <AppFrame>
      <ProductInfo product={product.data} />
      <ProductImages images={product.data.images} />
      <BatchList batches={product.data.batches} />
      <FeedbackList feedbacks={product.data.feedbacks} />
    </AppFrame>
  ) : (
    <Spinner />
  )
}
