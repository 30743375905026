import Modal from '../shared/Modal'
import Button from '../shared/Button'
import { Fragment, useState } from 'react'
import { Listbox, Transition, Switch } from '@headlessui/react'
import {
  CheckIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/20/solid'

const roles = [
  { id: 1, name: 'Owner' },
  { id: 2, name: 'Admin' },
  { id: 3, name: 'Member' },
  { id: 4, name: 'Read Only' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ProductModal({ closeModal }) {
  const [selected, setSelected] = useState(roles[3])
  const [enabled, setEnabled] = useState(true)

  return (
    <>
      <Modal
        title='Add Product'
        closeModal={closeModal}
      >
        <div>
          <label
            htmlFor='name'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Product Title
          </label>
          <div className='mt-2'>
            <input
              type='text'
              name='name'
              id='name'
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-400 sm:text-sm sm:leading-6'
              placeholder='Add Name'
            />
          </div>
        </div>
        <div>
          <label
            htmlFor='name'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Product Description 2
          </label>
          <div className='mt-2'>
            <textarea
              name='description'
              id='description'
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-400 sm:text-sm sm:leading-6'
              placeholder='Add Product Description'
            />
          </div>
        </div>
        <Switch.Group
          as='div'
          className='flex items-center justify-between'
        >
          <span className='flex flex-grow flex-col'>
            <Switch.Label
              as='span'
              className='text-sm font-medium leading-6 text-gray-900'
              passive
            >
              Company Information
            </Switch.Label>
            <Switch.Description
              as='span'
              className='text-sm text-gray-500'
            >
              Include company information in item scans.
            </Switch.Description>
          </span>
          <Switch
            checked={enabled}
            onChange={setEnabled}
            className={classNames(
              enabled ? 'bg-theme-400' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-theme-400 focus:ring-offset-2'
            )}
          >
            <span
              aria-hidden='true'
              className={classNames(
                enabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              )}
            />
          </Switch>
        </Switch.Group>
        <div className='mt-4'>
          <Button
            text='Save User'
            handleClick={closeModal}
          />
        </div>
      </Modal>
    </>
  )
}
