import AppFrameNoLogin from '../components/shared/AppFrameNoLogin'
import UsersDisplay from '../components/shared/UsersDisplay'
import Login from '../components/shared/Login'

export default function Home() {
  return (
    <>
      <AppFrameNoLogin>
        <Login />
      </AppFrameNoLogin>
    </>
  )
}
