import AppFrame from '../components/shared/AppFrame'
import UsersDisplay from '../components/shared/UsersDisplay'
import StatsBar from '../components/dashboard/StatsBar'
import StatsBody from '../components/dashboard/StatsBody'

export default function Dashboard() {
  return (
    <>
      <AppFrame>
        <div className='p-3 text-3xl text-theme-700 font-semibold'>
          Dashboard
        </div>
        <StatsBar />
        <div className='p-3'></div>
        <StatsBody />
      </AppFrame>
    </>
  )
}
