import ButtonLink from '../shared/ButtonLink'

function ProductReorder({ url, authentic }) {
  if (!authentic) return false
  if (!url) return false
  return (
    <>
      <div className='space-y-6 pt-8 p-4'>
        <h1>Would you like to reorder this product?</h1>

        <ButtonLink
          handleClick={url}
          className='flex w-full justify-center rounded-md bg-theme-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-theme-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-700'
          text='Reorder Product'
        ></ButtonLink>
      </div>
    </>
  )
}

export default ProductReorder
