import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom'
import React from 'react'
import Home from './pages/Home'
import Dashboard from './pages/Dashboard'
import Users from './pages/Users'
import Products from './pages/Products'
import Batches from './pages/Batches'
import StravaGummies from './pages/m/StravaGummies'
import Product from './pages/products/Product'
import MobileProduct from './pages/m/MobileProduct'
import ContactMan from './pages/m/ContactMan'
import ShippingLabel from './pages/m/ShippingLabel'
import PrivateRoute from './components/shared/PrivateRoute'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import NotFound from './pages/NotFound'

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path='/'
            element={<NotFound />}
          />
          <Route
            path='/avs-admin-login'
            element={<Home />}
          />
          <Route
            path='/dashboard'
            element={<PrivateRoute />}
          >
            <Route
              path='/dashboard'
              element={<Dashboard />}
            />
          </Route>
          <Route
            path='/users'
            element={<PrivateRoute />}
          >
            <Route
              path='/users'
              element={<Users />}
            />
          </Route>
          <Route
            path='/products'
            element={<PrivateRoute />}
          >
            <Route
              path='/products'
              element={<Products />}
            />
          </Route>
          <Route
            path='/batches'
            element={<PrivateRoute />}
          >
            <Route
              path='/batches'
              element={<Batches />}
            />
          </Route>
          <Route
            path='/products/:pid'
            element={<PrivateRoute />}
          >
            <Route
              path='/products/:pid'
              element={<Product />}
            />
          </Route>
          <Route
            path='/m/:pid'
            element={<MobileProduct />}
          />
          <Route
            path='/c/:pid'
            element={<ContactMan />}
          />
          <Route
            path='/shipping'
            element={<ShippingLabel />}
          />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  )
}

export default App
