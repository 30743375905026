import Modal from '../shared/Modal'
import Button from '../shared/Button'
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import {
  CheckIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/20/solid'

const roles = [
  { id: 1, name: '5 Star' },
  { id: 2, name: 'Yes / No' },
  { id: 3, name: 'Thumbs Up / Down' },
  { id: 4, name: 'Smiley / Frown' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function FeeedbackModal({ closeModal }) {
  const [selected, setSelected] = useState(roles[3])

  return (
    <>
      <Modal
        title='Feedback Question'
        closeModal={closeModal}
      >
        <div>
          <label
            htmlFor='question'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Question
          </label>
          <div className='mt-2'>
            <input
              type='text'
              name='question'
              id='question'
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-400 sm:text-sm sm:leading-6'
              placeholder='Add your feedback question.'
            />
          </div>
        </div>

        <Listbox
          value={selected}
          onChange={setSelected}
        >
          {({ open }) => (
            <>
              <Listbox.Label className='block text-sm font-medium leading-6 text-gray-900'>
                Type
              </Listbox.Label>
              <div className='relative mt-2'>
                <Listbox.Button className='relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-theme-400 sm:text-sm sm:leading-6'>
                  <span className='block truncate'>
                    {selected.name}
                  </span>
                  <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                    <ChevronUpDownIcon
                      className='h-5 w-5 text-gray-400'
                      aria-hidden='true'
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave='transition ease-in duration-100'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                    {roles.map((role) => (
                      <Listbox.Option
                        key={role.id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'bg-theme-400 text-white'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={role}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected
                                  ? 'font-semibold'
                                  : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {role.name}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active
                                    ? 'text-white'
                                    : 'text-theme-400',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon
                                  className='h-5 w-5'
                                  aria-hidden='true'
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        <div className='mt-4'>
          <Button
            text='Save User'
            handleClick={closeModal}
          />
        </div>
      </Modal>
    </>
  )
}
