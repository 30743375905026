import {
  XCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/20/solid'

function ProductAuthenticated({ authentic, track }) {
  var message = ''
  var icon = ''

  console.log(authentic)

  if (track) {
    if (authentic) {
      message = (
        <div className='text-green-600'>
          This product has been authenticated.
        </div>
      )
      icon = (
        <CheckCircleIcon className='rounded-full relative shadow-lg text-white w-10 h-10 -ml-5 z-50 bg-green-600'></CheckCircleIcon>
      )
    } else if (!authentic) {
      message = (
        <div className='text-red-600'>
          Warning this product cannot be authenticated.
        </div>
      )
      icon = (
        <XCircleIcon className='rounded-full relative shadow-lg text-white w-10 h-10 -ml-5 z-50 bg-red-600'></XCircleIcon>
      )
    }
  }

  return (
    <>
      <div className='bg-theme-100/50'>
        <div className='text-center w-[90%] max-w-md m-auto '>
          <div className='absolute'>{icon}</div>
          {message}
        </div>
      </div>
    </>
  )
}

export default ProductAuthenticated
