import { PaperClipIcon } from '@heroicons/react/20/solid'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import Button from '../shared/Button'
import ImageModal from './ImageModal'

const products = [
  {
    id: 1,
    images: [
      {
        title: 'GUMMIES1.WEBP',
        size: '257 KB',
        source:
          'https://imagizer.imageshack.com/img923/5993/2WEDol.jpg',
      },
      {
        title: 'GUMMIES2.JPEG',
        size: '18 KB',
        source:
          'https://imagizer.imageshack.com/img923/6043/lwInEi.jpg',
      },
      {
        title: 'GUMMIES3.WEBP',
        size: '48 KB',
        source:
          'https://imagizer.imageshack.com/img924/234/YVAQhj.jpg',
      },
    ],
  },
  {
    id: 2,
    images: [
      {
        title: 'LIONSMANE.WEBP',
        size: '52 KB',
        source:
          'https://imagizer.imageshack.com/img923/5993/2WEDol.jpg',
      },
      {
        title: 'IMAGE3.JPEG',
        size: '48 KB',
        source:
          'https://imagizer.imageshack.com/img924/934/a8ezkN.jpg',
      },
    ],
  },
  {
    id: 3,
    images: [
      {
        title: 'IMAGE.JPG',
        size: '110 KB',
        source:
          'https://imagizer.imageshack.com/img924/1048/1LNI2J.jpg',
      },
      {
        title: 'IMAGE2.JPG',
        size: '69 KB',
        source:
          'https://imagizer.imageshack.com/img924/9057/YTVp6y.jpg',
      },
    ],
  },
  {
    id: 4,
    images: [
      {
        title: 'IMAGE4.JPG',
        size: '205 KB',
        source:
          'https://imagizer.imageshack.com/img922/3391/gs4sIi.jpg',
      },
      {
        title: 'IMAGE5.PNG',
        size: '166 KB',
        source:
          'https://imagizer.imageshack.com/img923/4097/P55GpM.png',
      },
    ],
  },
]

export default function ProductImages(i) {
  const { pid } = useParams()

  const product = products.find(({ id }) => id == 1)

  let images = []
  if (i) {
    images = i.images
  }

  const [showModal, setShowModal] = useState(false)

  function openModal() {
    console.log('Opening Modal')
    setShowModal(true)
  }

  function closeModal() {
    console.log('closing modal')
    setShowModal(false)
  }

  return (
    <>
      <div className='mb-8 bg-theme-100/50 p-4'>
        <div className='sm:flex sm:items-center mb-4'>
          <div className='sm:flex-auto'>
            <h1 className='text-base font-semibold leading-7 text-gray-900'>
              Product Images
            </h1>
            <p className='mt-2 text-sm text-gray-700'>
              Below are the images that will show when a person scans
              an item in this product line. You can add and remove
              images here.
            </p>
          </div>
          <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
            <Button
              handleClick={openModal}
              text='Add Image'
            />
          </div>
        </div>
        {/* Add images here */}
        <ul
          role='list'
          className='grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8'
        >
          {images.map((file) => (
            <li
              key={file.source}
              className='relative'
            >
              <div className='group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100'>
                <img
                  src={file.source}
                  alt=''
                  className='pointer-events-none object-cover group-hover:opacity-75'
                />
                <button
                  type='button'
                  className='absolute inset-0 focus:outline-none'
                >
                  <span className='sr-only'>
                    View details for {file.title}
                  </span>
                </button>
              </div>
              <p className='pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900'>
                {file.title}
              </p>
              <p className='pointer-events-none block text-sm font-medium text-gray-500'>
                {file.size}
              </p>
            </li>
          ))}
        </ul>
        {/* End of product images */}
      </div>
      {showModal && <ImageModal closeModal={closeModal} />}
    </>
  )
}
