import logo from '../../components/assets/logo-strava.svg'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import CompanyInformation from '../../components/m/CompanyInformation'
import ButtonLink from '../../components/shared/ButtonLink'

const products = [
  {
    id: 1,
    nft: '5d7a514b5d2c12c7449be045',
    authentic: 'yes',
    title: 'Strava Gummies',
    description:
      'These Strava gummies are a new product from Strava, striving to reach the perfect balance between relaxed energy and focused creativity.',
    include_company: true,
    reorder_url:
      'https://www.stravacraftcoffee.com/products/whole-bean-intro-strength',
    images: [
      {
        title: 'GUMMIES1.WEBP',
        size: '257 KB',
        source:
          'https://imagizer.imageshack.com/img923/5993/2WEDol.jpg',
      },
      {
        title: 'GUMMIES2.JPEG',
        size: '18 KB',
        source:
          'https://imagizer.imageshack.com/img923/6043/lwInEi.jpg',
      },
      {
        title: 'GUMMIES3.WEBP',
        size: '48 KB',
        source:
          'https://imagizer.imageshack.com/img924/234/YVAQhj.jpg',
      },
    ],
    feedbacks: [
      {
        id: 1,
        question: 'Does the packaging grab your eye?',
        type: 'FeedbackYesNo',
        average: 0.87,
        feedback: 12,
      },
      {
        id: 2,
        question: 'Rate your enjoyment of the taste.',
        type: 'FeedbackFiveStar',
        average: 5.0,
        feedback: 21,
      },
      {
        id: 3,
        question:
          'Please rate your enjoyment of the feeling, one hour after consuming',
        type: 'FeedbackFiveStar',
        average: 4.0,
        feedback: 11,
      },
      {
        id: 4,
        question: 'Would you buy this product?',
        type: 'FeedbackFiveStar',
        average: 4.5,
        feedback: 18,
      },
    ],
  },
  {
    id: 2,
    nft: '89v7hdfsd9nds787hdbpkv5',
    authentic: 'yes',
    title: "Lion's Mane Choclatte Bar",
    description:
      'This selection starts with authentic Guatemalan chocolate and incorporates locally grown Lion’s Mane. The powerful adaptogenic properties are validated through laboratory testing, eunsuring quality with every batch.',
    include_company: true,
    reorder_url:
      'https://www.stravacraftcoffee.com/products/whole-bean-intro-strength',
    images: [
      {
        title: 'LIONSMANE.WEBP',
        size: '52 KB',
        source:
          'https://imagizer.imageshack.com/img923/659/KcpPD0.jpg',
      },
      {
        title: 'IMAGE3.JPEG',
        size: '48 KB',
        source:
          'https://imagizer.imageshack.com/img924/934/a8ezkN.jpg',
      },
    ],
    feedbacks: [
      {
        id: 5,
        question: "Do you use any other Lion's Mane products?",
        type: 'FeedbackYesNo',
        average: 0.6,
        feedback: 12,
      },
      {
        id: 6,
        question:
          "Did you find that this Lion's Mane product achieved what you wanted",
        type: 'FeedbackSmiles',
        average: 0.72,
        feedback: 12,
      },
    ],
  },
  {
    id: 3,
    nft: '23qond878dklcm6dscg5svc3',
    authentic: 'no',
    title: 'Blue :Lotus Capsule',
    description:
      'Blue lotus flower is an Egyptian water lily used to promote sleep, reduce anxiety, improve sexual performance, and address mental health conditions. These caspules contain blue lotus obtained directly from Egypt, harvest from the Nile delta region.',
    include_company: true,
    reorder_url:
      'https://www.stravacraftcoffee.com/products/whole-bean-intro-strength',
    images: [
      {
        title: 'IMAGE.JPG',
        size: '110 KB',
        source:
          'https://imagizer.imageshack.com/img924/1048/1LNI2J.jpg',
      },
      {
        title: 'IMAGE2.JPG',
        size: '69 KB',
        source:
          'https://imagizer.imageshack.com/img924/9057/YTVp6y.jpg',
      },
    ],
    feedbacks: [
      {
        id: 7,
        question: 'Did this product reduce anxiety for you?',
        type: 'FeedbackSmiles',
        average: 1.0,
        feedback: 12,
      },
      {
        id: 8,
        question:
          'Compared to your sleep in the last 60 days, how was your sleep for the several days following your use of Blue Lotus?',
        type: 'FeedbackFiveStar',
        average: 4.9,
        feedback: 20,
      },
    ],
  },
  {
    id: 4,
    nft: 'odfmg5f0v9djvmfd7his0981',
    authentic: '',
    title: 'Cacao Infused Coffee',
    description:
      "This serving consists of the award winning Strava coffee you are used to, infused with the highest quality cacao available. Authentic cacao can refer to ceremonial cacao, which is a high-quality, traditional chocolate that mimics the spiritual practices of the Maya and Aztec people. Ceremonial cacao is made by fermenting, lightly toasting, and peeling the beans, then grinding them into a thick paste. This process retains the bean's natural fat, which helps balance stimulating properties and facilitate absorption over time. Ceremonial cacao is said to be sacred and pure, and can nourish the body and mind in ways that regular cacao powder cannot.",
    include_company: false,
    reorder_url: '',
    images: [
      {
        title: 'IMAGE4.JPG',
        size: '205 KB',
        source:
          'https://imagizer.imageshack.com/img922/3391/gs4sIi.jpg',
      },
      {
        title: 'IMAGE5.PNG',
        size: '166 KB',
        source:
          'https://imagizer.imageshack.com/img923/4097/P55GpM.png',
      },
    ],
    feedbacks: [
      {
        id: 9,
        question: 'Are you experienced with cacao?',
        type: 'FeedbackYesNo',
        average: 0.45,
        feedback: 19,
      },
      {
        id: 10,
        question: 'Please rate your enjoyment of this product',
        type: 'FeedbackFiveStar',
        average: 4.8,
        feedback: 17,
      },
      {
        id: 11,
        question: 'Would you recemend this product to others?',
        type: 'FeedbackThubs',
        average: 0.45,
        feedback: 19,
      },
    ],
  },
]

const company = {
  name: 'Strava',
  address1: '123 W Union St.',
  address2: 'Suite 225',
  city: 'Englewood',
  state: 'CO',
  zip: '80123',
  phone: '1(888)323-9870',
  link: 'https://www.stravacraftcoffee.com/',
  description:
    'Since 2015, Strava Craft Coffee has been committed to perfecting the art of coffee roasting. We prioritize health and wellness and have dedicated years to perfecting the infusion of organic hemp oil into our specialty coffee beans. By combining the energizing effects of coffee with the calming benefits of CBD, we have created a smooth and well-balanced taste that distinguishes our coffees from others. As pioneers in CBD-infused coffee, we are always seeking ways to innovate and offer our customers the most delicious and healthful cup of coffee possible.',
  logo: '',
}

function ShippingLabel() {
  const [open, setOpen] = useState(false)
  const { nftid } = useParams()
  const product = products.find(({ nft }) => nft == nftid)

  return (
    <>
      <div className='bg-white'>
        <header className='relative bg-theme-100'>
          <nav
            aria-label='Top'
            className='mx-auto max-w-7xl px-4 sm:px-8 lg:px-8'
          >
            <div className='border-b border-gray-200'>
              <div className='flex h-16 items-center justify-between'>
                <div className='flex flex-1 items-center lg:hidden'></div>
                {/* Logo */}
                <a
                  href='#'
                  className='flex'
                >
                  <span className='sr-only'>{company.name}</span>
                  <img
                    className='h-16 w-auto'
                    src={logo}
                    alt=''
                  />
                </a>

                <div className='flex flex-1 items-center justify-end'></div>
              </div>
            </div>
          </nav>
        </header>
        <main className='p-4'>
          <p>
            Thank you for sending us this product. Your safety is our
            highest priority. We apologize that you have received an
            unauthenticated product but we want to make sure the
            product you consume is of the highest quality.
          </p>
          <div>
            <img src='https://imagizer.imageshack.com/img922/8904/57dfqV.png' />
          </div>
          <ButtonLink
            handleClick={'#'}
            className='flex w-full justify-center rounded-md bg-theme-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-theme-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-700'
            text='Print Label'
          ></ButtonLink>
        </main>
        <CompanyInformation company={company} />
      </div>
    </>
  )
}

export default ShippingLabel
