function ProductDescription({ description, authentic }) {
  if (!authentic) return false
  return (
    <>
      <div className='px-4 sm:mt-16 sm:px-0 lg:mt-0'>
        <div className=''>
          <h3 className='sr-only'>Description</h3>
          <div className='space-y-6 text-base text-gray-700' />
          <p>{description}</p>
        </div>
      </div>
    </>
  )
}

export default ProductDescription
