import logo from '../assets/logo-strava.svg'

function CompanyInformation({ company }) {
  return (
    <>
      <div className='flex justify-center mt-8 bg-theme-100'>
        <img
          className='h-24 w-auto'
          src={logo}
          alt='Your Company'
        />
      </div>
      <div className='p-4 bg-theme-100 flex justify-center'>
        <a
          className='text-theme-400 text-center'
          href='https://www.stravacraftcoffee.com/'
        >
          Visit us at our website.
        </a>
      </div>
      <div className='p-4 bg-theme-100'>
        <p>{company.description}-</p>
      </div>
    </>
  )
}

export default CompanyInformation
