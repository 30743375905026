import axios from 'axios'

const API_USERS_URL = '/api/v1/users'

//Add user
const createUser = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.post(API_USERS_URL, userData, config)

  return response.data
}

//Get users
const getUsers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(API_USERS_URL, config)

  return response.data
}

const userService = {
  createUser,
  getUsers,
}

export default userService
