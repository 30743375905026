import axios from 'axios'

const API_USERS_URL = '/api/v1/users'
const API_AUTH_URL = '/api/v1/auth/login'

const login = async (userData) => {
  console.log('Starting Login: ', userData)
  const response = await axios.post(API_AUTH_URL, userData)

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }

  return response.data
}

//Logout user
const logout = () => localStorage.removeItem('user')

const authService = {
  logout,
  login,
}

export default authService
