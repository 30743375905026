import { useState } from 'react'
import Button from '../shared/Button'
import BatchModal from '../product/BatchModal'
import FeeedbackModal from './FeedbackModal'
import { useParams } from 'react-router-dom'

const products = [
  {
    id: 1,
    feedbacks: [
      {
        id: 1,
        question: 'Does the packaging grab your eye?',
        type: 'yes/no',
        average: 0.87,
        feedback: 12,
      },
      {
        id: 2,
        question: 'Rate your enjoyment of the taste.',
        type: '5-star',
        average: 5.0,
        feedback: 21,
      },
      {
        id: 3,
        question:
          'Please rate your enjoyment of the feeling, one hour after consuming',
        type: '5-star',
        average: 4.0,
        feedback: 11,
      },
      {
        id: 4,
        question: 'Would you buy this product?',
        type: '5-star',
        average: 4.5,
        feedback: 18,
      },
    ],
  },
  {
    id: 2,
    feedbacks: [
      {
        id: 5,
        question: "Do you use any other Lion's Mane products?",
        type: 'yes/no',
        average: 0.6,
        feedback: 12,
      },
      {
        id: 6,
        question:
          "Did you find that this Lion's Mane product achieved what you wanted",
        type: 'smiles',
        average: 0.72,
        feedback: 12,
      },
    ],
  },
  {
    id: 3,
    feedbacks: [
      {
        id: 7,
        question: 'Did this product reduce anxiety for you?',
        type: 'smiles',
        average: 1.0,
        feedback: 12,
      },
      {
        id: 8,
        question:
          'Compared to your sleep in the last 60 days, how was your sleep for the several days following your use of Blue Lotus?',
        type: '5 star',
        average: 4.9,
        feedback: 20,
      },
    ],
  },
  {
    id: 4,
    feedbacks: [
      {
        id: 9,
        question: 'Are you experienced with cacao?',
        type: 'yes/no',
        average: 0.45,
        feedback: 19,
      },
      {
        id: 10,
        question: 'Please rate your enjoyment of this product',
        type: '5 star',
        average: 4.8,
        feedback: 17,
      },
      {
        id: 11,
        question: 'Would you recemend this product to others?',
        type: 'thumbs',
        average: 0.45,
        feedback: 19,
      },
    ],
  },
]

const batches = [
  {
    id: 1,
    question: '20240516.LMCB',
    type: 5,
    average: 823,
    feedback: 12,
  },
  {
    id: 2,
    question: '20240510.LMCB',
    type: 12,
    average: 1026,
    feedback: 18,
  },
]

export default function FeedbackList(f) {
  const { pid } = useParams()

  const product = products.find(({ id }) => id == 1)

  let feedbacks = []
  if (f) {
    feedbacks = f.feedbacks
  }
  console.log('FEEDBACKS: ', feedbacks)

  const [showModal, setShowModal] = useState(false)

  function openModal() {
    console.log('Opening Modal')
    setShowModal(true)
  }

  function closeModal() {
    console.log('closing modal')
    setShowModal(false)
  }

  return (
    <>
      <div className=''>
        <div className='sm:flex sm:items-center'>
          <div className='sm:flex-auto'>
            <h1 className='text-base font-semibold leading-6 text-gray-900'>
              Feedback
            </h1>
            <p className='mt-2 text-sm text-gray-700'>
              A list of all the feedback questions that will be asked
              on intem scans.
            </p>
          </div>
          <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
            <Button
              handleClick={openModal}
              text='Add Question'
            />
          </div>
        </div>
        <div className='mt-8 flow-root'>
          <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                    >
                      Question
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Type
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Average
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Feedback
                    </th>
                    <th
                      scope='col'
                      className='relative py-3.5 pl-3 pr-4 sm:pr-3'
                    >
                      <span className='sr-only'>Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white'>
                  {feedbacks.map((feedback) => (
                    <tr
                      key={feedback.id}
                      className='even:bg-gray-50'
                    >
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                        {feedback.question}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {feedback.type}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {feedback.average}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        0
                      </td>
                      <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3'>
                        <a
                          href='#'
                          className='text-theme-400 hover:text-theme-500'
                        >
                          Download
                          <span className='sr-only'>
                            , {feedback.id}
                          </span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal && <FeeedbackModal closeModal={closeModal} />}
    </>
  )
}
