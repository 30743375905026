import axios from 'axios'

const API_PRODUCTS_URL = '/api/v1/products'

//Add user
const createProduct = async (productData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.post(
    API_PRODUCTS_URL,
    productData,
    config
  )

  return response.data
}

//Get users
const getProducts = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(API_PRODUCTS_URL, config)

  return response.data
}

//Get product
const getProduct = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(
    API_PRODUCTS_URL + '/' + id,
    config
  )

  return response.data
}

//Get mobile product
const getMobileProduct = async (id) => {
  const response = await axios.get(API_PRODUCTS_URL + '/' + id)
  console.log('getMobileProduct Product Service')

  return response.data
}

const productService = {
  createProduct,
  getProducts,
  getProduct,
  getMobileProduct,
}

export default productService
