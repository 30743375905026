import {
  HandThumbUpIcon,
  HandThumbDownIcon,
  FaceSmileIcon,
  FaceFrownIcon,
} from '@heroicons/react/24/outline'
import {
  StarIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid'
import React, { useState } from 'react'
import axios from 'axios'

function ProductFeedback({ feedbacks, authentic, pid }) {
  const [rating, setRating] = useState(0)
  const API_FEEDBACKS_URL = '/api/v1/feedbacks'
  const API_FEEDBACK_LOGGING_URL = '/api/v1/feedbacklogging'
  const uuid = localStorage.getItem('uuid')
  const handleRatingChange = (newRating, id) => {
    console.log('Feedback Logging:', pid, id, uuid, newRating)
    setRating(rating + 1)
    localStorage.setItem(id, newRating)
    const data = { rating: newRating }
    axios.put(API_FEEDBACKS_URL + '/' + id + '/rate', data)
    const loggingData = {
      uuid: uuid,
      product: pid,
      feedback: id,
      rating: newRating,
    }
    axios.post(API_FEEDBACK_LOGGING_URL, loggingData)
  }

  if (!authentic) return false
  function FeedbackFiveStar(id) {
    const value = localStorage.getItem(id)

    switch (value) {
      case 'one':
        return (
          <>
            <div class='flex gap-2 [&>.item:hover]:fill-theme-400 [&>.item:has(~.item:hover)]:fill-theme-400 justify-center'>
              <StarIcon
                class='item fill-theme-400 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='one'
                onClick={() => handleRatingChange('one', id)}
              />
              <StarIcon
                class='item fill-gray-300 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='two'
                onClick={() => handleRatingChange('two', id)}
              />
              <StarIcon
                class='item fill-gray-300 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='three'
                onClick={() => handleRatingChange('three', id)}
              />
              <StarIcon
                class='item fill-gray-300 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='ofourne'
                onClick={() => handleRatingChange('four', id)}
              />
              <StarIcon
                class='item fill-gray-300 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='five'
                onClick={() => handleRatingChange('five', id)}
              />
            </div>
          </>
        )
      case 'two':
        return (
          <>
            <div class='flex gap-2 [&>.item:hover]:fill-theme-400 [&>.item:has(~.item:hover)]:fill-theme-400 justify-center'>
              <StarIcon
                class='item fill-theme-400 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='one'
                onClick={() => handleRatingChange('one', id)}
              />
              <StarIcon
                class='item fill-theme-400 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='two'
                onClick={() => handleRatingChange('two', id)}
              />
              <StarIcon
                class='item fill-gray-300 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='three'
                onClick={() => handleRatingChange('three', id)}
              />
              <StarIcon
                class='item fill-gray-300 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='ofourne'
                onClick={() => handleRatingChange('four', id)}
              />
              <StarIcon
                class='item fill-gray-300 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='five'
                onClick={() => handleRatingChange('five', id)}
              />
            </div>
          </>
        )
      case 'three':
        return (
          <>
            <div class='flex gap-2 [&>.item:hover]:fill-theme-400 [&>.item:has(~.item:hover)]:fill-theme-400 justify-center'>
              <StarIcon
                class='item fill-theme-400 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='one'
                onClick={() => handleRatingChange('one', id)}
              />
              <StarIcon
                class='item fill-theme-400 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='two'
                onClick={() => handleRatingChange('two', id)}
              />
              <StarIcon
                class='item fill-theme-400 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='three'
                onClick={() => handleRatingChange('three', id)}
              />
              <StarIcon
                class='item fill-gray-300 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='ofourne'
                onClick={() => handleRatingChange('four', id)}
              />
              <StarIcon
                class='item fill-gray-300 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='five'
                onClick={() => handleRatingChange('five', id)}
              />
            </div>
          </>
        )
      case 'four':
        return (
          <>
            <div class='flex gap-2 [&>.item:hover]:fill-theme-400 [&>.item:has(~.item:hover)]:fill-theme-400 justify-center'>
              <StarIcon
                class='item fill-theme-400 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='one'
                onClick={() => handleRatingChange('one', id)}
              />
              <StarIcon
                class='item fill-theme-400 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='two'
                onClick={() => handleRatingChange('two', id)}
              />
              <StarIcon
                class='item fill-theme-400 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='three'
                onClick={() => handleRatingChange('three', id)}
              />
              <StarIcon
                class='item fill-theme-400 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='ofourne'
                onClick={() => handleRatingChange('four', id)}
              />
              <StarIcon
                class='item fill-gray-300 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='five'
                onClick={() => handleRatingChange('five', id)}
              />
            </div>
          </>
        )
      case 'five':
        return (
          <>
            <div class='flex gap-2 [&>.item:hover]:fill-theme-400 [&>.item:has(~.item:hover)]:fill-theme-400 justify-center'>
              <StarIcon
                class='item fill-theme-400 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='one'
                onClick={() => handleRatingChange('one', id)}
              />
              <StarIcon
                class='item fill-theme-400 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='two'
                onClick={() => handleRatingChange('two', id)}
              />
              <StarIcon
                class='item fill-theme-400 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='three'
                onClick={() => handleRatingChange('three', id)}
              />
              <StarIcon
                class='item fill-theme-400 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='ofourne'
                onClick={() => handleRatingChange('four', id)}
              />
              <StarIcon
                class='item fill-theme-400 h-12 w-12 flex justify-center items-center text-white text-6xl'
                key='five'
                onClick={() => handleRatingChange('five', id)}
              />
            </div>
          </>
        )
      default:
        return (
          <>
            <div class='flex gap-2 [&>.item:hover]:fill-theme-400 [&>.item:has(~.item:hover)]:fill-theme-400 justify-center'>
              {['one', 'two', 'three', 'four', 'five'].map(
                (value) => (
                  <StarIcon
                    class='item fill-gray-300 h-12 w-12 flex justify-center items-center text-white text-6xl'
                    key={value}
                    onClick={() => handleRatingChange(value, id)}
                  />
                )
              )}
            </div>
          </>
        )
    }
  }
  function FeedbackThubs(id) {
    return (
      <>
        <div className='text-center'>
          <span className='flex gap-10 m-2 justify-center'>
            <HandThumbUpIcon
              key='y'
              onClick={() => handleRatingChange('y', id)}
              className=' hover:text-theme-400 text-gray-300 w-12 h-12 mx-2'
            />
            <HandThumbDownIcon
              key='n'
              onClick={() => handleRatingChange('n', id)}
              className='hover:text-theme-400 text-gray-300 w-12 h-12 mx-2'
            />
          </span>
        </div>
      </>
    )
  }
  function FeedbackYesNo(id) {
    const value = localStorage.getItem(id)
    switch (value) {
      case 'y':
        return (
          <>
            <div className='text-center'>
              <span className='flex gap-10 m-2 justify-center'>
                <CheckCircleIcon
                  key='y'
                  onClick={() => handleRatingChange('y', id)}
                  className='fill-theme-400 hover:text-theme-400 text-gray-300 w-12 h-12 mx-2'
                />
                <XCircleIcon
                  key='n'
                  onClick={() => handleRatingChange('n', id)}
                  className='hover:text-theme-400 text-gray-300 w-12 h-12 mx-2'
                />
              </span>
            </div>
          </>
        )
      case 'n':
        return (
          <>
            <div className='text-center'>
              <span className='flex gap-10 m-2 justify-center'>
                <CheckCircleIcon
                  key='y'
                  onClick={() => handleRatingChange('y', id)}
                  className=' hover:text-theme-400 text-gray-300 w-12 h-12 mx-2'
                />
                <XCircleIcon
                  key='n'
                  onClick={() => handleRatingChange('n', id)}
                  className='fill-theme-400 hover:text-theme-400 text-gray-300 w-12 h-12 mx-2'
                />
              </span>
            </div>
          </>
        )
      default:
        return (
          <>
            <div className='text-center'>
              <span className='flex gap-10 m-2 justify-center'>
                <CheckCircleIcon
                  key='y'
                  onClick={() => handleRatingChange('y', id)}
                  className=' hover:text-theme-400 text-gray-300 w-12 h-12 mx-2'
                />
                <XCircleIcon
                  key='n'
                  onClick={() => handleRatingChange('n', id)}
                  className='hover:text-theme-400 text-gray-300 w-12 h-12 mx-2'
                />
              </span>
            </div>
          </>
        )
    }
  }
  function FeedbackSmiles(id) {
    const value = localStorage.getItem(id)
    switch (value) {
      case 'y':
        return (
          <>
            <div className='text-center'>
              <span className='flex gap-10 m-2 justify-center'>
                <FaceSmileIcon
                  key='y'
                  onClick={() => handleRatingChange('y', id)}
                  className=' hover:text-theme-400 text-theme-400 w-12 h-12 mx-2'
                />
                <FaceFrownIcon
                  key='y'
                  onClick={() => handleRatingChange('n', id)}
                  className='hover:text-theme-400 text-gray-300 w-12 h-12 mx-2'
                />
              </span>
            </div>
          </>
        )
      case 'n':
        return (
          <>
            <div className='text-center'>
              <span className='flex gap-10 m-2 justify-center'>
                <FaceSmileIcon
                  key='y'
                  onClick={() => handleRatingChange('y', id)}
                  className=' hover:text-theme-400 text-gray-300 w-12 h-12 mx-2'
                />
                <FaceFrownIcon
                  key='y'
                  onClick={() => handleRatingChange('n', id)}
                  className='hover:text-theme-400 text-theme-400 w-12 h-12 mx-2'
                />
              </span>
            </div>
          </>
        )
      default:
        return (
          <>
            <div className='text-center'>
              <span className='flex gap-10 m-2 justify-center'>
                <FaceSmileIcon
                  key='y'
                  onClick={() => handleRatingChange('y', id)}
                  className=' hover:text-theme-400 text-gray-300 w-12 h-12 mx-2'
                />
                <FaceFrownIcon
                  key='y'
                  onClick={() => handleRatingChange('n', id)}
                  className='hover:text-theme-400 text-gray-300 w-12 h-12 mx-2'
                />
              </span>
            </div>
          </>
        )
    }
  }

  return (
    <>
      <div className='mt-10 mb-10 bg-theme-100/50 p-4'>
        {feedbacks.map((feedback) => {
          console.log('Feedback ', feedback._id)
          return (
            <>
              <div className='pl-4 pt-4'>
                <div>{feedback.question}</div>
                {feedback.type === 'FiveStar' &&
                  FeedbackFiveStar(feedback._id)}
                {feedback.type === 'Thumbs' &&
                  FeedbackThubs(feedback._id)}
                {feedback.type === 'YesNo' &&
                  FeedbackYesNo(feedback._id)}
                {feedback.type === 'Smiles' &&
                  FeedbackSmiles(feedback._id)}
              </div>
            </>
          )
        })}
      </div>
    </>
  )
}

export default ProductFeedback
