import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid'
import { useState } from 'react'

export default function ImageCarousel({ slides }) {
  const [slideIndex, setSlideIndex] = useState(0)

  let previousSlide = () => {
    if (slideIndex === 0) setSlideIndex(slides.length - 1)
    else setSlideIndex(slideIndex - 1)
  }

  let nextSlide = () => {
    if (slideIndex === slides.length - 1) setSlideIndex(0)
    else setSlideIndex(slideIndex + 1)
  }

  return (
    <>
      <div className='bg-theme-100/50'>
        <div className=' w-[90%] max-w-md m-auto  pb-11'>
          <div className='overflow-hidden relative'>
            <div
              className='flex ease-out duration-300'
              style={{
                transform: `translateX(-${slideIndex * 100}%)`,
              }}
            >
              {slides.map((s) => {
                return (
                  <img
                    src={s.source}
                    className='object-cover w-full'
                  />
                )
              })}
            </div>
            <div className='absolute top-0 h-full w-full justify-between items-center flex px-2'>
              <button
                className='bg-white/40 rounded-full'
                onClick={previousSlide}
              >
                <ChevronLeftIcon className='h-8 w-8 text-theme-400' />
              </button>
              <button
                className='bg-white/40 rounded-full'
                onClick={nextSlide}
              >
                <ChevronRightIcon className='h-8 w-8 text-theme-400' />
              </button>

              <div className='absolute bottom-0 py-4 flex justify-center gap-2 w-full'>
                {slides.map((s, i) => {
                  return (
                    <div
                      onClick={() => {
                        setSlideIndex(i)
                      }}
                      key={'circle' + i}
                      className={`rounded-full cursor-pointer w-3 h-3 ${
                        i == slideIndex
                          ? 'bg-white/60'
                          : 'bg-white/40'
                      }`}
                    ></div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

{
  /*  
Chevron Left
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
</svg>

Chevron Right:
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
</svg>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
</svg>

 */
}
