import Modal from '../shared/Modal'
import Button from '../shared/Button'
import { Fragment, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { Listbox, Transition } from '@headlessui/react'
import { useSelector, useDispatch } from 'react-redux'
import { createUser, reset } from '../../features/users/userSlice'
import {
  CheckIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/20/solid'
import Spinner from '../../components/shared/Spinner'

const roles = [
  { id: 1, name: 'Owner' },
  { id: 2, name: 'Admin' },
  { id: 3, name: 'Member' },
  { id: 4, name: 'Read Only' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function UserModal({ closeModal }) {
  const [selected, setSelected] = useState(roles[3])

  const [formData, setFormData] = useState({
    name: '',
    title: '',
    email: '',
    role: 'user',
    password: '',
  })

  const { name, email, title, password } = formData

  const dispatch = useDispatch()

  const { users, isLoading, isSuccess, isError, isSaved, message } =
    useSelector((state) => state.users)

  useEffect(() => {
    dispatch(reset())
    if (isError) {
      toast.error(message)
    }
  }, [isError, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
    console.log(e.target.value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    console.log('Submitted')
    //closeModal()

    const userData = {
      name,
      email,
      title,
      password,
    }
    dispatch(createUser(userData))
    console.log('User data', userData)
  }

  // if (isLoading) {
  //   return <Spinner />
  // }

  return (
    <>
      <Modal
        title='Add user'
        closeModal={closeModal}
      >
        <form onSubmit={onSubmit}>
          <div>
            <label
              htmlFor='name'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Name
            </label>
            <div className='mt-2'>
              <input
                type='text'
                name='name'
                id='name'
                value={name}
                onChange={onChange}
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-400 sm:text-sm sm:leading-6'
                placeholder='Add Name'
                required
              />
            </div>
          </div>
          <div>
            <label
              htmlFor='title'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Title
            </label>
            <div className='mt-2'>
              <input
                type='text'
                name='title'
                id='title'
                value={title}
                onChange={onChange}
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-400 sm:text-sm sm:leading-6'
                placeholder='Add User Title'
              />
            </div>
          </div>
          <div>
            <label
              htmlFor='email'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Email
            </label>
            <div className='mt-2'>
              <input
                type='email'
                name='email'
                id='email'
                value={email}
                onChange={onChange}
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-400 sm:text-sm sm:leading-6'
                placeholder='you@example.com'
                required
              />
            </div>
          </div>
          <div>
            <label
              htmlFor='password'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Email
            </label>
            <div className='mt-2'>
              <input
                type='password'
                name='password'
                id='password'
                value={password}
                onChange={onChange}
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-400 sm:text-sm sm:leading-6'
                placeholder='you@example.com'
                required
              />
            </div>
          </div>
          <Listbox
            value={selected}
            onChange={setSelected}
          >
            {({ open }) => (
              <>
                <Listbox.Label className='block text-sm font-medium leading-6 text-gray-900'>
                  Assigned to
                </Listbox.Label>
                <div className='relative mt-2'>
                  <Listbox.Button className='relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-theme-400 sm:text-sm sm:leading-6'>
                    <span className='block truncate'>
                      {selected.name}
                    </span>
                    <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                      <ChevronUpDownIcon
                        className='h-5 w-5 text-gray-400'
                        aria-hidden='true'
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave='transition ease-in duration-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                      {roles.map((role) => (
                        <Listbox.Option
                          key={role.id}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'bg-theme-400 text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={role}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected
                                    ? 'font-semibold'
                                    : 'font-normal',
                                  'block truncate'
                                )}
                              >
                                {role.name}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active
                                      ? 'text-white'
                                      : 'text-theme-400',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon
                                    className='h-5 w-5'
                                    aria-hidden='true'
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          <div className='mt-4'>
            <Button
              text='Save User'
              handleClick={onSubmit}
            />
          </div>
        </form>
      </Modal>
    </>
  )
}
