import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUsers, reset } from '../../features/users/userSlice'
import Button from '../shared/Button'
import UserModal from './UserModal'
import Spinner from '../shared/Spinner'
import { toast } from 'react-toastify'

let people = []

export default function UserList() {
  const { users, isSaved, isError, isLoading, isSuccess, message } =
    useSelector((state) => state.users)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isSuccess) {
      dispatch(getUsers())
    }
    if (users.data) {
      people = users.data
    }
    if (isSaved) {
      dispatch(reset())
      toast.info('User has been saved')
      closeModal()
    }

    return () => {
      if (isLoading) {
        return <Spinner />
      }
    }
  }, [isSuccess, dispatch])

  const [showModal, setShowModal] = useState(false)

  function openModal() {
    console.log('Opening Modal')
    setShowModal(true)
  }

  function closeModal() {
    console.log('closing modal')
    setShowModal(false)
  }

  //console.log('Users: ', users.length)
  return (
    <>
      <div className='px-4 sm:px-6 lg:px-8'>
        <div className='sm:flex sm:items-center'>
          <div className='sm:flex-auto'>
            <h1 className='text-base font-semibold leading-6 text-gray-900'>
              Users
            </h1>
            <p className='mt-2 text-sm text-gray-700'>
              A list of all the users in your account including their
              name, title, email and role.
            </p>
          </div>
          <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
            <Button
              handleClick={openModal}
              text='Add User'
            />
          </div>
        </div>
        <div className='mt-8 flow-root'>
          <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                    >
                      Name
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Title
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Email
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Role
                    </th>
                    <th
                      scope='col'
                      className='relative py-3.5 pl-3 pr-4 sm:pr-3'
                    >
                      <span className='sr-only'>Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white'>
                  {people.map((person) => (
                    <tr
                      key={person.email}
                      className='even:bg-gray-50'
                    >
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                        {person.name}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {person.title}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {person.email}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {person.role}
                      </td>
                      <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3'>
                        <a
                          href='#'
                          className='text-theme-400 hover:text-theme-500'
                        >
                          Edit
                          <span className='sr-only'>
                            , {person.name}
                          </span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal && <UserModal closeModal={closeModal} />}
    </>
  )
}
