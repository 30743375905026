function NFTLink({ authentic, url, nft, track }) {
  if (authentic && track)
    return (
      <>
        <div className='flex justify-center'>
          <a
            href={url}
            className='text-sm text-center mb-5 text-gray-600'
          >
            NFT: {nft}
          </a>
        </div>
      </>
    )
  else {
    return false
  }
}

export default NFTLink
