import AppFrame from '../components/shared/AppFrame'
import UserList from '../components/users/UserList'

export default function Users() {
  return (
    <>
      <AppFrame>
        <UserList />
      </AppFrame>
    </>
  )
}
