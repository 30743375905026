import AppFrame from '../components/shared/AppFrame'
import ProductList from '../components/products/ProductList'

export default function Products() {
  return (
    <>
      <AppFrame>
        <ProductList />
      </AppFrame>
    </>
  )
}
