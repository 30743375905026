import axios from 'axios'
import { v4 as uuid } from 'uuid'
import logo from '../../components/assets/logo-strava.svg'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getMobileProduct } from '../../features/products/productSlice'
import ProductTitle from '../../components/m/ProductTitle'
import ImageCarousel from '../../components/m/ImageCarousel'
import ProductDescription from '../../components/m/ProductDescription'
import ProductFeedback from '../../components/m/ProductFeedback'
import CompanyInformation from '../../components/m/CompanyInformation'
import ProductSubcribe from '../../components/m/ProductSubscribe'
import ProductAuthenticated from '../../components/m/ProductAuthenticated'
import ProductReorder from '../../components/m/ProductReorder'
import ProductContactMan from '../../components/m/ProductContactMan'
import Spinner from '../../components/shared/Spinner'

const company = {
  name: 'Strava',
  address1: '123 W Union St.',
  address2: 'Suite 225',
  city: 'Englewood',
  state: 'CO',
  zip: '80123',
  phone: '1(888)323-9870',
  link: 'https://www.stravacraftcoffee.com/',
  description:
    'Since 2015, Strava Craft Coffee has been committed to perfecting the art of coffee roasting. We prioritize health and wellness and have dedicated years to perfecting the infusion of organic hemp oil into our specialty coffee beans. By combining the energizing effects of coffee with the calming benefits of CBD, we have created a smooth and well-balanced taste that distinguishes our coffees from others. As pioneers in CBD-infused coffee, we are always seeking ways to innovate and offer our customers the most delicious and healthful cup of coffee possible.',
  logo: '',
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

//TODO:I should move these axios calls that dont need to a response to a helper file.

let check = false

function updateViewCounts(id) {
  let userId = localStorage.getItem('uuid')
  let data = { new: false }
  if (!userId) {
    userId = uuid()
    localStorage.setItem('uuid', userId)
    data = { new: true }
  }
  if (!check) {
    check = true
    const API_PRODUCTS_URL = '/api/v1/products'
    axios.put(API_PRODUCTS_URL + '/' + id + '/count', data)
  }
}

export default function MobileProduct() {
  const navigate = useNavigate()
  const { pid } = useParams()
  const view = { new: true }

  const { product, isSaved, isError, isLoading, isSuccess, message } =
    useSelector((state) => state.products)
  const dispatch = useDispatch()

  console.log('PID: ', pid)

  useEffect(() => {
    dispatch(getMobileProduct(pid))
  }, [dispatch])

  const [open, setOpen] = useState(false)
  //const { id } = useParams()
  //const p = products.find(
  //  ({ nft }) => nft == '0xd665ee841be2f06adc3f2ca56676f529a624347c'
  //)
  if (!product.data) {
    console.log('No product data')
    //navigate('/')
  }
  console.log('The Product: ', product.data)
  const p = product.data

  let toLoad = false

  if (isSuccess) {
    updateViewCounts(pid, view)
    if (p.redirect) {
      setTimeout(function () {
        document.location.href = p.reorder_url
      }, 500)
    } else {
      toLoad = true
    }
  }

  return toLoad ? (
    <div className='bg-white'>
      <header className='relative bg-theme-100'>
        <nav
          aria-label='Top'
          className='mx-auto max-w-7xl px-4 sm:px-8 lg:px-8'
        >
          <div className='border-b border-gray-200'>
            <div className='flex h-16 items-center justify-between'>
              <div className='flex flex-1 items-center lg:hidden'></div>
              {/* Logo */}
              <a
                href='#'
                className='flex'
              >
                <span className='sr-only'>{company.name}</span>
                <img
                  className='h-16 w-auto'
                  src={logo}
                  alt=''
                />
              </a>

              <div className='flex flex-1 items-center justify-end'></div>
            </div>
          </div>
        </nav>
      </header>
      <main>
        <ProductAuthenticated
          authentic={p.authentic}
          track={p.track}
        />
        <ImageCarousel slides={p.images} />

        <ProductTitle
          title={p.title}
          nft={p.product_nft}
          id2={p.id2}
          authentic={p.authentic}
          track={p.track}
        />

        <ProductDescription
          description={p.description}
          authentic={p.authentic}
        />

        <ProductContactMan
          nft={p.product_nft}
          authentic={p.authentic}
          track={p.track}
        />

        <ProductReorder
          url={p.reorder_url}
          authentic={p.authentic}
        />
        {console.log('AUTHENTIC ' + p.authentic)}
        <ProductFeedback
          feedbacks={p.feedbacks}
          authentic={p.authentic}
          pid={pid}
        />

        <ProductSubcribe authentic={p.authentic} />

        <CompanyInformation company={company} />
      </main>
    </div>
  ) : (
    <Spinner />
  )
}
