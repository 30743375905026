import Modal from '../shared/Modal'
import Button from '../shared/Button'
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import {
  CheckIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/20/solid'

const roles = [
  { id: 1, name: 'Owner' },
  { id: 2, name: 'Admin' },
  { id: 3, name: 'Member' },
  { id: 4, name: 'Read Only' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function BatchModal({ closeModal }) {
  const [selected, setSelected] = useState(roles[3])

  return (
    <>
      <Modal
        title='Add Batch'
        closeModal={closeModal}
      >
        <div>
          <label
            htmlFor='name'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Batch Name
          </label>
          <div className='mt-2'>
            <input
              type='text'
              name='name'
              id='name'
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-400 sm:text-sm sm:leading-6'
              placeholder='Add Name'
            />
          </div>
        </div>
        <div>
          <label
            htmlFor='name'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Item Count
          </label>
          <div className='mt-2'>
            <input
              type='text'
              name='name'
              id='name'
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-400 sm:text-sm sm:leading-6'
              placeholder='Number of items in batch'
            />
          </div>
        </div>

        <div className='mt-4'>
          <Button
            text='Save Batch'
            handleClick={closeModal}
          />
        </div>
      </Modal>
    </>
  )
}
