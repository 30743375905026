import ButtonLink from '../shared/ButtonLink'

function ProductContactMan({ nft, authentic }) {
  if (authentic) return false
  if (!authentic)
    return (
      <>
        <div className='space-y-6 pt-8 p-4'>
          <h1>
            Would you like to contact the manufacturer to send back
            your product for a free authenticated product replacement?
          </h1>

          <ButtonLink
            handleClick={'/c/' + nft}
            className='flex w-full justify-center rounded-md bg-theme-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-theme-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-700'
            text='Contact manufacturer'
          ></ButtonLink>
        </div>
      </>
    )
}

export default ProductContactMan
