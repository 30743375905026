import { useState, useEffect } from 'react'
import Button from '../shared/Button'
import Modal from '../shared/Modal'
import ProductModal from '../products/ProductModal'
import { useSelector, useDispatch } from 'react-redux'
import {
  getProducts,
  reset,
} from '../../features/products/productSlice'
import Spinner from '../shared/Spinner'
import { toast } from 'react-toastify'

let theProducts = [
  {
    id: 1,
    name: 'Strava Gummies',
    batches: 1,
    scans: 823,
    feedback: 12,
    subscribers: 7,
    counterfeit: 0,
  },
  {
    id: 2,
    name: "Lion's Mane Choclatte Bar",
    batches: 7,
    scans: 3999,
    feedback: 409,
    subscribers: 262,
    counterfeit: 0,
  },
  {
    id: 3,
    name: 'Blue: Lotus Capsule',
    batches: 5,
    scans: 486,
    feedback: 23,
    subscribers: 36,
    counterfeit: 1,
  },
  {
    id: 4,
    name: 'Cacao Infused Coffee',
    batches: 4,
    scans: 913,
    feedback: 104,
    subscribers: 59,
    counterfeit: 0,
  },
]

let theNewProducts = []
export default function ProductList() {
  const {
    products,
    isSaved,
    isError,
    isLoading,
    isSuccess,
    message,
  } = useSelector((state) => state.products)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isSuccess) {
      dispatch(getProducts())
    }
    if (products.data) {
      theNewProducts = products.data
      console.log('The new products: ', theNewProducts)
    }
    if (isSaved) {
      dispatch(reset())
      toast.info('User has been saved')
      closeModal()
    }

    return () => {
      if (isLoading) {
        return <Spinner />
      }
    }
  }, [isSuccess, dispatch])

  const [showModal, setShowModal] = useState(false)

  function openModal() {
    console.log('Opening Modal')
    setShowModal(true)
  }

  function closeModal() {
    console.log('closing modal')
    setShowModal(false)
  }

  return (
    <>
      <div className='px-4 sm:px-6 lg:px-8'>
        <div className='sm:flex sm:items-center'>
          <div className='sm:flex-auto'>
            <h1 className='text-base font-semibold leading-6 text-gray-900'>
              Products
            </h1>
            <p className='mt-2 text-sm text-gray-700'>
              A list of all the products that you have registered.
              Click the edit button to add images, new batches or to
              modify the feedback sections of the product line.
            </p>
          </div>
          <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
            <Button
              handleClick={openModal}
              text='Add Product'
            />
          </div>
        </div>
        <div className='mt-8 flow-root'>
          <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                    >
                      Product Name
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Batches
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Scans
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Feedback
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Connections
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Counterfeit
                    </th>
                    <th
                      scope='col'
                      className='relative py-3.5 pl-3 pr-4 sm:pr-3'
                    >
                      <span className='sr-only'>Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white'>
                  {theNewProducts.map((product) => (
                    <tr
                      key={product._id}
                      className='even:bg-gray-50'
                    >
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                        <a href={'products/' + product.id}>
                          {product.title}
                        </a>
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        0
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        0
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        0
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        0
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        0
                      </td>
                      <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3'>
                        <a
                          href={'products/' + product.id}
                          className='text-theme-400 hover:text-theme-500'
                        >
                          Edit
                          <span className='sr-only'>
                            , {product.id}
                          </span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal && <ProductModal closeModal={closeModal} />}
    </>
  )
}
